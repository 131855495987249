<template lang="pug">
	v-card(outline tile)
		v-row(justify="center")
			v-col(cols="12" sm="8" )
				h6(class="text-body-2 text-center mt-5") En este módulo puedes construir segmentaciones con información relacionada a las compras que el cliente Coppel ha realizado (histórico desde 2013 a la fecha).
		ValidationObserver(ref='observer')
			v-card-text()
				v-row(justify="center" color="primary")
					v-col(cols='10' class="my-5" )
						v-divider
						p(class="text-h6 font-weight-bold blue-500--text mt-10 text-center") Temporalidad
						p(class="text-body-2 text-center mt-2 mb-0") En esta sección podrás seleccionar información con la periodicidad de las compras del cliente
				//- Dates
				v-row
					v-col(cols='12' sm='6' md='4')
						v-menu(
							:close-on-content-click="false"
							:nudge-right="40"
							transition="scale-transition"
							offset-y
							min-width="auto")
							template(v-slot:activator='{ on, attrs }')
								p( class="text-title font-weight-bold blue-500--text mt-5") Fecha de facturación de compra
								v-text-field(
									v-model="step_data.fec_FechaFacturacion"
									label="Selecciona una fecha o intervalo de fechas"
									prepend-icon="mdi-calendar"
									readonly
									v-bind="attrs"
									v-on="on")
							v-date-picker(
								v-model="step_data.fec_FechaFacturacion"
								@input="menu = false"
								@change="fecFacturacionCompraDatePicker"
								range
								:max="max_date")
					v-col(cols='12' sm='6' md='4')
						v-menu(
							:close-on-content-click="false"
							:nudge-right="40"
							transition="scale-transition"
							offset-y
							min-width="auto")
							template(v-slot:activator='{ on, attrs }')
								p( class="text-title font-weight-bold blue-500--text mt-5") Fecha de orden de compra
								v-text-field(
									v-model="step_data.fec_fechaorden"
									label="Selecciona una fecha o intervalo de fechas"
									prepend-icon="mdi-calendar"
									readonly
									v-bind="attrs"
									v-on="on")
							v-date-picker(
								v-model="step_data.fec_fechaorden"
								@input="menu = false"
								range
								@change="fecOrdenCompraDatePicker"
								:max="max_date")
				
				v-row(justify="center")
					v-col(cols='10' )
						v-divider(class="my-5")
						p( class="text-h6 font-weight-bold blue-500--text mt-5 text-center") Hábitos de compra
						p(class="text-body-2 text-center mt-2 mb-0") En esta sección puedes filtrar las características del cliente Coppel
				v-row

					v-col(cols='12' md='4')
						//- ValidationProvider(v-slot='{ errors }' name='canal abono' rules='required')
						p( class="text-title font-weight-bold blue-500--text mt-5") Canal de venta
						v-select( 
								:items="canalVentaItems" 
								label="Selecciona una o varias opciones"
								v-model='step_data.des_canalventa'
								multiple chips)
				
				
					v-col(cols='12' md='4')
						p( class="text-title font-weight-bold blue-500--text mt-5") Dispositivo utilizado
						v-select( 
								:items="dispositivoUtilizadoItems" 
								label="Selecciona una o varias opciones"
								v-model='step_data.des_Dispositivo'
								multiple chips)

				
					v-col(cols='12' md='4')
						p( class="text-title font-weight-bold blue-500--text mt-5") Tipo de compra
						v-select( 
								:items="tipoCompraItems" 
								label="Selecciona una o varias opciones"
								v-model='step_data.des_tipocompra'
								multiple chips)
					
					v-col(cols='12' md='4')
						p( class="text-title font-weight-bold blue-500--text mt-5") Tipo de pago inicial
						v-select( 
								:items="pagoInicialItems" 
								label="Selecciona una o varias opciones"
								v-model='step_data.des_pagoinicial'
								multiple chips)
				
				v-row(justify="center")
					v-col(cols='10' )
						v-divider(class="my-5")
						p( class="text-h6 font-weight-bold blue-500--text mt-5 text-center") Información del artículo
						p(class="text-body-2 text-center mt-2 mb-0") En esta sección podrás elegir información relacionada a las características del producto y/o artículos adquiridos por el cliente Coppel.

				v-row
					v-col(cols='12' md='4')
						p( class="text-title font-weight-bold blue-500--text mt-5") Área productos
						v-select( 
								:items="areaItems" 
								label="Selecciona una o varias opciones"
								v-model='step_data.des_Area'
								multiple chips)

					v-col(cols='12' md='4') 
						p( class="text-title font-weight-bold blue-500--text mt-5") Categoría de productos
						v-select( 
								:items="categoriesItems"
								label="Selecciona una o varias opciones"
								v-model='step_data.des_categoria'
								multiple chips)

					v-col(cols='12' md='4') 
						p( class="text-title font-weight-bold blue-500--text mt-5") Subcategoría de productos
						v-select( 
								:items="subcategoriesItems"
								label="Selecciona una o varias opciones"
								v-model='step_data.des_subcategoria'
								multiple chips)

					v-col(cols='12' md='4') 
						p( class="text-title font-weight-bold blue-500--text mt-5") Departamento de productos
						v-select( 
								:items="depProductsItems" 
								label="Selecciona una o varias opciones"
								v-model='step_data.des_departamento'
								multiple chips)
					
					v-col(cols='12' md='4')
						p( class="text-title font-weight-bold blue-500--text mt-5") Cantidad de artículos 
						label(class="text-caption") Selecciona el intervalo de la cantidad de artículos adquiridos por el cliente.
						p(class="text-caption text-end mt-1") Rango: {{step_data.cantidad_articulos[0]}} - {{step_data.cantidad_articulos[1]}}
						v-range-slider(
							step='1'
							min='0' max='10'
							v-model="step_data.cantidad_articulos"
							class="mt-10")
				v-row

					v-col(cols='12' md='4') 
						p( class="text-title font-weight-bold blue-500--text mt-5") Familia
						label(class="text-caption") Busca y elige una o varias familias
						v-autocomplete(
							:loading="loadingInputFamilia"
							v-model="step_data.des_familia"
							:items="familyItems"
							append-icon=""
							append-outer-icon="mdi-magnify"
							chips
							label="Busca y elige una o varias familias"
							small-chips
							clearable
							multiple
						)

					v-col(cols='12' md='4') 
						p( class="text-title font-weight-bold blue-500--text mt-5") Clase
						label(class="text-caption") Busca y elige una o varias clases
						v-autocomplete(
							append-icon=""
							append-outer-icon="mdi-magnify"
							:loading="loadingInputClase"
							v-model="step_data.des_clase"
							:items="clasesItems"
							chips
							label="Busca y elige una o varias clases"
							small-chips
							clearable
							multiple
						)

					v-col(cols='12' md='4') 
						p( class="text-title font-weight-bold blue-500--text mt-5") Marca
						label(class="text-caption") Busca y elige una o varias marcas
						v-autocomplete(
							append-icon=""
							append-outer-icon="mdi-magnify"
							:loading="loadingInputMarcas"
							v-model="step_data.des_marca"
							:items="marcasItems"
							chips
							label="Busca y elige una o varias marcas"
							small-chips
							clearable
							multiple
						)

					v-col(cols='12' md='4') 
						p( class="text-title font-weight-bold blue-500--text mt-5") Descripción de artículo
						label(class="text-caption") Busca y elige una o varias descripciones
						v-autocomplete(
							append-icon=""
							append-outer-icon="mdi-magnify"
							:loading="loadingInputArticulosDesc"
							v-model="step_data.des_articulo"
							:items="desArticulosItems"
							chips
							label="Busca y elige una o varias descripciones"
							small-chips
							clearable
							multiple
						)

					v-col(cols='12' md='4') 
						p( class="text-title font-weight-bold blue-500--text mt-5") Nombre del artículo
						label(class="text-caption") Busca y elige uno o varios nombres
						v-autocomplete(
							append-icon=""
							append-outer-icon="mdi-magnify"
							v-model="step_data.nom_articulo"
							:items="nomArticulosItems"
							:loading="loadingInputArticulos"
							chips
							label="Busca y elige uno o varios nombres"
							small-chips
							clearable
							multiple
						)

				
				v-row(justify="center")
					v-col(cols='10' )
						v-divider(class="my-5")
						p( class="text-h6 font-weight-bold blue-500--text mt-5 text-center") Precios
						p(class="text-body-2 text-center mt-2 mb-0") En esta sección podrás seleccionar información relacionada con los montos de compra

				v-row
					
					v-col(cols='12' md='4')
						p( class="text-title font-weight-bold blue-500--text mt-5") Precio de articulo a crédito (MXN)
						label(class="text-caption") Selecciona el intervalo del precio a crédito del artículo comprado por el cliente
						p(class="text-caption text-end mt-1") Rango: {{step_data.precio_articulo_credito[0]}} - {{step_data.precio_articulo_credito[1]}}
						v-range-slider(
							step='1'
							min='0' max='110000'
							v-model="step_data.precio_articulo_credito"
							class="mt-10")

					v-col(cols='12' md='4')
						p( class="text-title font-weight-bold blue-500--text mt-5") Precio de articulo contado (MXN)
						label(class="text-caption") Selecciona el intervalo del precio de contado del artículo comprado por el cliente
						p(class="text-caption text-end mt-1") Rango: {{step_data.precio_articulo_contado[0]}} - {{step_data.precio_articulo_contado[1]}}
						v-range-slider(
							step='1'
							min='0' max='110000'
							v-model="step_data.precio_articulo_contado"
							class="mt-10")
					
			v-card-actions.px-4.text-end(justify='end')
				v-btn.ml-auto(color='primary' @click='onClickSaveBtn')  Siguiente
</template>

<script>

import { arrPlainEquals } from '../../../utils/helpers'
import  UseCasesService from '../../../services/UseCasesService';
const useCasesService = new UseCasesService();

export default {
	name: 'ComportamientoCompraComponent',
	
	 data: () => ({
		
		loadingInputClase: true,
		loadingInputFamilia: true,
		loadingInputMarcas: true,
		loadingInputArticulosDesc: true,
		loadingInputArticulos: true,

		max_date: new Date().toISOString().substr(0, 10),
		menu: false,

		canalVentaItems: [
			{text:"Coppel.com",value:"COPPEL.COM"},
			{text:"App Coppel",value:"APP COPPEL"},
			{text:"Coppel.com en tienda",value:"COPPEL.COM EN TIENDA"},
			{text:"Mercado Libre",value:"MERCADO LIBRE"},
		],
		
		// jalar de base de datos
		dispositivoUtilizadoItems: [
			{text:"Android",value:"ANDROID"},
			{text:"Desktop",value:"DESKTOP"},
			{text:"IOS",value:"IOS"},
			{text:"Mobile",value:"MOBILE"},
			{text:"Tablet",value:"TABLET"},
			{text:"Sin dato",value:"unidentified"},
		],

		tipoCompraItems: ['Crédito Coppel', 'Venta Empleado', 'Contado'], 

		pagoInicialItems: [ 'Con pago inicial', 'Sin pago inicial', 'Contado' ],

		areaItems: [
			{text:"Muebles",value:"Muebles"},
			{text:"Ropa",value:"Ropa"},
			{text:"Sin dato",value:"unidentified"},

		],

		// jalar de base de datos
		categoriesItems: [
			'Bebes', 'Blancos', 'Caballero Exterior', 'Caballeros Accesorios', 'Celulares', 'Cuidado Personal',
			'Dama Joven', 'Dama Señora', 'Deportivo', 'Enseres Domésticos', 'Escolar', 'Hogar', 'Impulso', 
			'Interior y Calceteria', 'Joyería y Relojería', 'Juguetes', 'Juguetes, Bebés y Aparatos de Ejercicio', 'Lencería', 
			'Línea Blanca', 'MEJORAMIENTO DEL HOGAR', 'Mascotas', 'Mejoramiento del Hogar', 'Niñas', 'Niños', 
			'Recamara', 'Sin Categoría', 'Sin categoría', 'Tecnologia y Entretenimiento', 'Transporte y Movilidad', 'Zapato Caballero',
			'Zapato Dama', 'Zapato Infantil', 'Óptica'

		],

		// jalar de base de datos
		subcategoriesItems: [
			'Accesorios', 'Accesorios Motos', 'Accesorios Óptica', 'Aparatos de Ejercicio', 'Articulos de Oficina', 'Aseo de Casa',
			'Audio', 'Audio Personal', 'Automotriz', 'Baño', 'Bebes', 'Bicicletas', 
			'Blancos', 'Caballero Exterior', 'Caballeros Accesorios', 'Camas', 'Car Audio', 'Clima y Ventilación', 
			'Colchones', 'Comedor', 'Cuidado Personal', 'Cámaras', 'Cómputo', 'Dama Joven', 
			'Dama Señora', 'Decoración', 'Deportivo', 'Domesticos', 'Electrodomesticos', 'Energia', 
			'Equipaje', 'Equipaje de Computo', 'Equipaje de Viaje', 'Escolar', 'Exclusivos BanCoppel', 'Focos', 
			'Herramientas', 'Impulso', 'Instrumentos Musicales', 'Interior y Calceteria', 'Joyería', 'Juguetes', 
			'Lencería', 'Lente solar', 'Lente solar E-commerce', 'Lentes de contacto', 'Línea Blanca', 'Maquinas de Coser', 
			'Mascotas', 'Motos', 'Movilidad Electrica', 'Muebles de Cocina', 'Muebles de Jardín', 'Muebles para Recamara', 
			'Niñas', 'Niños', 'Ofertas', 'Oficina', 'Oftalmico', 'Outdoor', 
			'Planchado', 'Promociones', 'Relojeria', 'Salas', 'Salud y Bienestar', 'Sin Subcategoría', 
			'Sin subcategoría', 'Tarjetas de Contenido', 'Telefonos Celulares', 'Telefonos Fijos', 'Video', 'VideoJuegos', 
			'Vista cansada', 'Zapato Caballero', 'Zapato Dama', 'Zapato Infantil',
		],

		depProductsItems: [
			{text:"BBS", value:"BBS"},
			{text:"Bicicletas, bebes, automotriz", value:"BICICLETAS/BEBES/AUTOMOTRIZ"},
			{text:"Blancos", value:"BLANCOS"},
			{text:"Caballeros", value:"CABALLEROS"},
			{text:"Damas", value:"DAMAS"},
			{text:"domésticos", value:"DOMESTICOS"},
			{text:"Electrónica", value:"ELECTRONICA"},
			{text:"Joyería", value:"JOYERIA"},
			{text:"Línea blanca", value:"LINEA BLANCA"},
			{text:"Mueble suelto", value:"MUEBLE SUELTO"},
			{text:"Niñas", value:"NIÑAS"},
			{text:"Niños", value:"NIÑOS"},
			{text:"Óptica", value:"OPTICA"},
			{text:"Perfuería y cosméticos", value:"PERF COSM"},
			{text:"Relojería", value:"RELOJERIA"},
			{text:"Salas, recámaras, comedores", value:"SALAS, RECAMARAS, COMEDORES"},
			{text:"Zapatería", value:"ZAPATERIA"},
			{text:"Sin dato", value:"unidentified"},
		],

		familyItems: [],
		clasesItems: [],
		marcasItems: [],
		desArticulosItems: [],
		nomArticulosItems: [],

		step_data :{
			tab: 'comportamientocompra',

			fec_FechaFacturacion: [],
			fec_fechaorden: [],
			des_canalventa: [],
			des_Dispositivo: [],
			des_tipocompra: [],
			des_pagoinicial: [],
			des_Area: [],
			des_categoria: [],
			des_subcategoria: [],
			des_departamento: [],
			cantidad_articulos: [0,0],
			des_familia: [],
			des_clase: [],
			des_marca: [],
			des_articulo: [],
			nom_articulo: [],
			precio_articulo_credito: [0,0],
			precio_articulo_contado: [0,0],

		},
		temp_data : {}
	
	}),

	created(){
		this.init();
	},

	methods:{
		/*
		init() {
			this.loading = true;
			segmenterService.get_data_for_inputs_comportamiendo_compra()
			.then(response => {
				this.familyItems = response.data.families;
				this.clasesItems = response.data.clases;
				this.marcasItems = response.data.marcas;
				this.desArticulosItems = response.data.desc_articulos;
				this.nomArticulosItems = response.data.nom_articulos;
			})
			.catch(errors => {
				console.log(errors)
			})
			.then(()=>{
				this.loading = false;
			})
		},
		*/

		init() {
			this.loadingInputClase = true;
			this.loadingInputFamilia = true;
			this.loadingInputMarcas = true;
			this.loadingInputArticulosDesc = true;
			this.loadingInputArticulos = true;

			useCasesService.jsonArticulosClase()
			.then(response => {
				this.clasesItems = response.data.data;
			})
			.catch(errors => {
				console.log(errors)
			})
			.then(()=>{
				this.loadingInputClase = false;
			})

            useCasesService.jsonArticulosFamilia()
			.then(response => {
				this.familyItems = response.data.data;
			})
			.catch(errors => {
				console.log(errors)
			})
			.then(()=>{
                this.loadingInputFamilia = false;
			})

            useCasesService.jsonArticulosMarca()
			.then(response => {
				this.marcasItems = response.data.data;
			})
			.catch(errors => {
				console.log(errors)
			})
			.then(()=>{
                this.loadingInputMarcas = false;
			})

            useCasesService.jsonArticulosDescripcion()
			.then(response => {
				this.desArticulosItems = response.data.data;
			})
			.catch(errors => {
				console.log(errors)
			})
			.then(()=>{
                this.loadingInputArticulosDesc = false;
			})

            useCasesService.jsonArticulosNombre()
			.then(response => {
				this.nomArticulosItems = response.data.data;
			})
			.catch(errors => {
				console.log(errors)
			})
			.then(()=>{
                this.loadingInputArticulos = false;
			})
		},

		onClickSaveBtn:  function() {
			this.$refs.observer.validate().then( async res=> {
				//- if the form is valid
				if(res){
					this.$store.dispatch('update_step_state', { step1: this.willStepBeSent() })
					await this.process_fields()
					.then(resolve=>{
						this.$store.dispatch('step1_data',this.temp_data);
						this.$emit('nextStep');
					})
					.catch(error=>{
						console.log(error);
					});
				}
			})
		},
		// si la fecha 1 es mayor a la fecha 2 en el rango se invierten los valores
		evalRangeDatePicker(range) {
			var _range = Object.assign([], range)
			if ( new Date(_range[0]).getTime() > new Date(_range[1]).getTime() ) {
				const [from, to] = _range;
				_range = [to, from];
			}
			return _range;
		},
		fecFacturacionCompraDatePicker: function(range) {
			this.step_data.fec_FechaFacturacion = this.evalRangeDatePicker(range);
		},
		fecOrdenCompraDatePicker: function(range) {
			this.step_data.fec_fechaorden = this.evalRangeDatePicker(range);
		},

		process_fields:  function(){
			this.temp_data = {}
			return  new Promise((resolve, reject)=>{
				
				for(const field in this.step_data){
					const val = this.step_data[field];

					if(Array.isArray(val) && val.length>0 && !arrPlainEquals( val, [0,0] ) ){
						this.temp_data[field] = val;
					}

				}
				
				this.temp_data['tab'] = this.step_data.tab;

				resolve();
			})
		},
		willStepBeSent: function() {

			if ( this.step_data.fec_FechaFacturacion.length > 0 ||
				this.step_data.fec_fechaorden.length > 0 ||
				this.step_data.des_canalventa.length > 0 ||
				this.step_data.des_Dispositivo.length > 0 ||
				this.step_data.des_tipocompra.length > 0 ||
				this.step_data.des_pagoinicial.length > 0 ||
				this.step_data.des_Area.length > 0 ||
				this.step_data.des_categoria.length > 0 ||
				this.step_data.des_subcategoria.length > 0 ||
				this.step_data.des_departamento.length > 0 ||

				this.step_data.des_familia.length > 0 ||
				this.step_data.des_clase.length > 0 ||
				this.step_data.des_marca.length > 0 ||
				this.step_data.des_articulo.length > 0 ||
				this.step_data.nom_articulo.length > 0 ||

				this.step_data.des_Area.length > 0 ||
				!arrPlainEquals( this.step_data.cantidad_articulos, [0,0] ) ||
				!arrPlainEquals( this.step_data.precio_articulo_credito, [0,0] ) ||
				!arrPlainEquals( this.step_data.precio_articulo_contado, [0,0] ) ) {
				
				return true;
			}

			return false;
		}
	},

	
}
</script>