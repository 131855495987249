<template lang="pug">
v-card(outline tile)
	v-row(justify="center")
		v-col( cols="12" sm="12" class="ml-5 text-center" )
			//- h2( class="text-h5 font-weight-bold blue-500--text mt-5") ¿ Requieres agregar un filtro de tipo Sociodemográficos?
			p(class="text-body-2 text-center mt-2 mb-0") En este módulo puedes filtrar por características sociodemográficas del cliente.

	//- v-row(justify="center")
	//- 	v-col( cols="12" sm="3" class="ml-5 text-center" )
	//- 		v-radio-group( v-model="isRequiredFilter" row)
	//- 			v-radio(label="Sí" :value="true")
	//- 			v-radio(label="No" :value="false")
	ValidationObserver(ref='observer')
		v-card-text(v-if="isRequiredFilter")
			v-row(justify="center" color="primary")
				v-col(cols='12' sm="10" class="my-5" )
					v-divider
			v-row( color="primary")
				v-col(cols='12' sm="4")
					p( class="text-title font-weight-bold blue-500--text mt-5") Género
					v-select( 
						:items="sexo" 
						label="Selecciona una o varias opciones"
						v-model='step_data.sexo'
						multiple chips)

				v-col(cols='12' sm="3")
					p( class="text-title font-weight-bold blue-500--text mt-5") Edad
					v-select( 
						:items="olds_item"
						v-model="opc_old"
						label="Seleciona una opción" chips)
				v-col(cols='12' sm="5")
					p( class="text-title font-weight-bold blue-500--text mt-5 mb-5") {{opc_old}} 
					template(v-if="opc_old=='Manual'")
						p(class="text-caption mb-0") Elige los minimos y maximos de edad del cliente que necesitas. (años)
						ValidationProvider(v-slot='{ errors }' name='edad' rules='required')
							v-range-slider(
								:error-messages='errors'
								thumb-label='always'
								step='1'
								min='18' max='85'
								v-model="step_data.manual_old"
								class="mt-10")
					template(v-if="opc_old=='Rangos de edad'")
						p(class="text-caption mb-0") Selecciona uno o varios rangos de tu interés. Se encuentran divididos por grupos quinquenales de edad.
						ValidationProvider(v-slot='{ errors }' name='rangos de edad' rules='required')
							v-select(
								:items="ranges_old"
								label="Selecciona los rangos de tu interes"
								:error-messages='errors'
								v-model="step_data.ranges_old"
								chips multiple
								class="mt-1")
					template(v-if="opc_old=='Etapas de vida'")
						ValidationProvider(v-slot='{ errors }' name='Etapas Edad' rules='required')
							v-row
								v-col(sm="10")
									p(class="text-caption mb-0") Selecciona una o varias categorías. La descripción de las categorías se encuentran en el  botón de dudas.

									v-select(
										:items="steps_old"
										label="Selecciona la etapa de vida para tu análisis"
										:error-messages='errors'
										v-model="step_data.steps_old"
										chips multiple
										class="mt-1 d-inline")
								v-col(sm="2")
									v-tooltip( top class="d-inline")
										template(v-slot:activator='{ on, attrs }')
											v-btn(icon color='primary' v-bind='attrs' v-on='on')
												v-icon mdi-help-circle
										p
											| <span class="font-weight-black">Adolescentes:</span> Clientes con edades entre 15 y 24 años.
											| <br/>
											| <span  class="font-weight-black">Adultos jovenes:</span> Clientes con edades entre 25 y 39 años.
											| <br/>
											| <span  class="font-weight-black">Adultos:</span> Clientes con edades entre 40 y 64 años.
											| <br/>
											| <span  class="font-weight-black">Jubilados:</span> Clientes con 65 años y más

					template(v-if="opc_old=='Generación'")
						ValidationProvider(v-slot='{ errors }' name='etapas edad' rules='required')
							v-row
								v-col(sm="10")
									p(class="text-caption mb-0")  Selecciona una o varias categorías. La descripción de las categorías se encuentran en el  botón de dudas.
									v-select(
										:items="generation_old"
										label="Elige la generación adecuada a tu campaña"
										:error-messages='errors'
										v-model="step_data.generation_old"
										chips multiple
										class="mt-1 d-inline")
								v-col(sm="2")
									v-tooltip( top class="d-inline")
										template(v-slot:activator='{ on, attrs }')
											v-btn(icon color='primary' v-bind='attrs' v-on='on')
												v-icon mdi-help-circle
										p
											| <span class="font-weight-black">Generación Z:</span> Clientes que nacieron en 1995 hasta el dia de hoy
											| <br/>
											| <span  class="font-weight-black">Millennials (Y):</span> Clientes que nacieron entre 1982 y 1994
											| <br/>
											| <span  class="font-weight-black">Generación X:</span> Clientes que nacieron entre 1965 y 1981
											| <br/>
											| <span  class="font-weight-black">Baby boom:</span> Clientes que nacieron entre 1945 y 1964
											| <br/>
											| <span  class="font-weight-black">Generación Silenciosa:</span> Clientes que nacieron entre 1925 y 1944

						
				v-col(cols='12' sm="4")
					p( class="text-title font-weight-bold blue-500--text mt-5") Nivel de escolaridad
					v-select( 
						:items="academic_level" 
						label="Selecciona una o varias opciones."
						v-model='step_data.escolaridad'
						multiple chips)
				v-col(cols='12' sm="4")
					p( class="text-title font-weight-bold blue-500--text mt-5") Estado Civil
					v-select( 
						:items="civil_status" 
						label="Selecciona una o varias opciones."
						v-model='step_data.estadocivil'
						multiple chips)
			v-row
				v-col(cols='12' sm="4")
					p( class="text-title font-weight-bold blue-500--text mt-5") Integrantes del hogar
					v-select( 
						:items="home_member" 
						label="Selecciona una o varias opciones."
						v-model='step_data.numpersonaseneldom'
						multiple chips)
				v-col(cols='12' sm="4")
					p( class="text-title font-weight-bold blue-500--text mt-5") Integrantes del hogar que trabajan
					v-select( 
						:items="home_member_workers" 
						label="Selecciona una o varias opciones."
						v-model='step_data.numpersonastrabajan'
						multiple chips)

			v-row(justify="center")
				v-col(cols='10' )
					v-divider(class="my-5")
					p( class="text-h6 font-weight-bold blue-500--text mt-5 text-center") Salario
					//- p(class="text-body-2 text-center mt-2 mb-0") Elige el ingreso del cliente que mejor considere.
			v-row
				v-col(cols='12' sm="4")
					p( class="text-title font-weight-bold blue-500--text mt-5") Categorías de salarios del cliente
					v-select( 
						:items="salary"
						v-model="opc_salary"
						label="Seleciona una opción" chips)
				v-col(cols='12' sm="5")
					p( class="text-title font-weight-bold blue-500--text mt-5 mb-5") {{opc_salary}} 
					template(v-if="opc_salary=='Salarios minimos'")
						ValidationProvider(v-slot='{ errors }' name='salario' rules='required')
							v-row
								v-col(sm="10")

									v-select(
										:items="min_salary"
										label="Selecciona una o varias opciones."
										:error-messages='errors'
										v-model="step_data.min_salary"
										chips multiple
										class="mt-1 d-inline")
									p La descripción del salario se encuentran en el botón de dudas.
								v-col(sm="2")
									v-tooltip( top class="d-inline")
										template(v-slot:activator='{ on, attrs }')
											v-btn(icon color='primary' v-bind='attrs' v-on='on')
												v-icon mdi-help-circle
										p
											| El <span class="font-weight-black">salario mínimo </span> es la cantidad mínima recibida por jornada de trabajo.
											| <br/> En 2021 el un <span class="font-weight-black">salario mínimo  </span>corresponde a  $141.70 pesos; mientras que en la Zona Libre de la Frontera Norte son $213.39 pesos. 
											| <br/> Para el cálculo de esta categoría se utiliza $141.70. 
											| <br/>
					template(v-if="opc_salary=='Ingreso promedio mensual'")
						p(class="text-caption mb-0") Elige los mínimos y máximos del salario promedio del cliente que necesitas. (Calculado en pesos MXN)
						ValidationProvider(v-slot='{ errors }' name='ingreso' rules='required')
							v-range-slider(
								:error-messages='errors'
								thumb-label='always'
								step='1000'
								min='0' max='100000'
								v-model="step_data.ing_salary"
								class="mt-10")
						p(class="text-caption mt-2") <b>Nota:</b> Cálculos generados por Analitica Digital con base en el salario mínimo del año 2021 de $141.70 pesos diarios.

					template(v-if="opc_salary=='Nivel socioecónomico'")
						ValidationProvider(v-slot='{ errors }' name='salario' rules='required')
							v-row
								v-col(sm="10")
									p(class="text-caption mb-0 ") El nivel de ingreso está relacionado con el Nivel Socioeconómico. 
									p(class="text-caption mb-0 ") La descripción del ingreso por NSE se encuentran en el botón de dudas.
									v-select(
										:items="level_salary"
										label=" Selecciona una o varias opciones."
										:error-messages='errors'
										v-model="step_data.level_salary"
										chips multiple
										class="mt-3")
									p(class="text-caption mb-0 ") <b>Nota:</b> Cálculos generados por Analitica Digital con base en el salario mínimo del año 2021 de $141.70 pesos diarios y la metodología AMAI 2020.

								v-col(sm="2")
									v-tooltip( top class="d-inline")
										template(v-slot:activator='{ on, attrs }')
											v-btn(icon color='primary' v-bind='attrs' v-on='on')
												v-icon mdi-help-circle
										p
											| <span class="font-weight-black">A/B:</span> Ingreso promedio mensual $94,864
											| <br/>
											| <span  class="font-weight-black">C+</span> Ingreso promedio mensual $39,061
											| <br/>
											| <span  class="font-weight-black">C:</span> Ingreso promedio mensual $22,205
											| <br/>
											| <span  class="font-weight-black">C-:</span> Ingreso promedio mensual $16,740
											| <br/>
											| <span  class="font-weight-black">D+:</span> Ingreso promedio mensual $12,945
											| <br/>
											| <span  class="font-weight-black">D:</span> Ingreso promedio mensual $7,833
											| <br/>
											| <span  class="font-weight-black">E:</span> Ingreso promedio mensual $3,712
											| <br/>


	v-card-actions.px-4.text-end(justify='end')
		v-btn.ml-auto(color='primary' @click='onClickSaveBtn')  Siguiente
</template>

<script>
//import { required, digits, email, max, regex } from 'vee-validate/dist/rules'

import { arrPlainEquals } from '../../../utils/helpers'

export default {
	name: 'SociodemograficosComponent',

	data: () => ({
		isRequiredFilter: true,

		sexo: [
			{text: "Hombre", value: 'M'},
			{text: "Mujer", value: 'F'},
			{text: "Sin identificar", value: 'unidentified'},
		],

		olds_item:["Manual","Rangos de edad","Etapas de vida","Generación"],
		opc_old:"",
		ranges_old:[
			{text:"15 a 19 años", value:[15,19]},
			{text:"20 a 24 años", value:[20,24]},
			{text:"25 a 29 años", value:[25,29]},
			{text:"30 a 34 años", value:[30,34]},

			{text:"35 a 39 años", value:[35,39]},
			{text:"40 a 44 años", value:[40,44]},
			{text:"45 a 49 años", value:[45,49]},
			{text:"50 a 54 años", value:[50,54]},

			{text:"55 a 59 años", value:[55,59]},
			{text:"60 a 64 años", value:[60,64]},
			{text:"65 a 69 años", value:[65,69]},
			{text:"70 a 74 años", value:[70,74]},

			{text:"75 a 79 años", value:[75,79]},
			{text:"80 a 84 años", value:[80,84]},
			{text:"85 años o más", value:[85,89]},
		],
		steps_old:[
			{text:"Adolescentes", value:[15,24]},
			{text:"Adultos jovenes", value:[25,39]},
			{text:"Adultos", value:[40,64]},
			{text:"Jubilados", value:[65,69]},
		],
		generation_old:[
			{text:"Generación Z", value:[1995,2025]},
			{text:"Millennials (Y)", value:[1982,1994]},
			{text:"Generación X", value:[1965,1981]},
			{text:"Baby boom", value:[1945,1964]},
			{text:"Generación Silenciosa", value:[1925,1944]},

		],
		academic_level:[
			{text:"Sin estudios", value:1},
			{text:"Primaria", value:2},
			{text:"Secundaria", value:3},
			{text:"Carrera técnica", value:4},
			{text:"Preparatoria", value:5},
			{text:"Profesional", value:6},
			{text:"No identificado", value:0},
		],

		civil_status:[
			{text:"Casado", value:"C"},
			{text:"Soltero", value:"S"},
			{text:"Union libre", value:"U"},
			{text:"Divorciado", value:"D"},
			{text: "Viudo", value:"V"},
			{text: "Sin identificar", value:"unidentified"}
		],

		home_member:[
			{text:"1 persona", value:1},
			{text:"2 personas", value:2},
			{text:"3 personas", value:3},
			{text:"4 personas", value:4},
			{text:"5 personas", value:5},
			{text:"6 o más personas", value:6},
			{text:"Sin dato", value: 0},
		],

		home_member_workers:[
			{text:"1 persona", value:1},
			{text:"2 personas", value:2},
			{text:"3 personas", value:3},
			{text:"4 personas", value:4},
			{text:"5 personas", value:5},
			{text:"Sin dato", value:0},
		],
		salary:["Salarios minimos","Ingreso promedio mensual","Nivel socioecónomico"],
		opc_salary:'',

		min_salary:[
			{text:"Menor o igual a 1 SM", value:[0,1]},
			{text:"Hasta 2 SM", value:[0,2]},
			{text:"Hasta 3 SM", value:[0,3]},
			{text:"Hasta 4 SM", value:[0,4]},
			{text:"de 5 a 10 SM", value:[5,10]},
			{text:"de 11 a 25 SM", value:[11,25]},
			{text:"Mayor a 25 SM", value:[25,1000000]}, // de >= 25
		],

		level_salary:[
			{text:"A/B", value:[25,1000000] }, // >= 25
			{text:"C+", value:[11,25] },
			{text:"C", value:[5,10] },
			{text:"C-", value:[0,4] },
			{text:"D+", value:[0,3] },
			{text:"D", value:[0,2] },
			{text:"E", value:[0,1] },
		],

		step_data :{
			tab: 'sociodemograficos',
			sexo:[],
			manual_old: [0,0],
			ranges_old:[],
			steps_old:[],
			generation_old: [],
			escolaridad:[],
			estadocivil:[],
			numpersonaseneldom:[],
			numpersonastrabajan:[],
			opc_salary:"",

			min_salary:[],
			ing_salary:[0,0],
			level_salary:[],
		},
		temp_data : {}
	}),
	watch: {
		opc_old: function(value) {
			// reset edad values
			this.step_data.manual_old = [0,0];
			this.step_data.ranges_old = [];
			this.step_data.steps_old  = [];
			this.step_data.generation_old = []; 
		},

		opc_salary: function(value) {
			this.step_data.min_salary = [];
			this.step_data.ing_salary = [0,0];
			this.step_data.level_salary = [];
		}
	},
	methods: {
		onClickSaveBtn:  function() {
			this.$refs.observer.validate().then( async res=> {
				//- if the form is valid
				if(res){
					console.log('valido ', this.willStepBeSent())
					this.$store.dispatch('update_step_state', { step1: this.willStepBeSent() })
					await this.process_fields()
					.then(resolve=>{
						this.$store.dispatch('step1_data',this.temp_data);
						this.$emit('nextStep');
					})
					.catch(error=>{
						console.log(error);
					});
				}
			})
		},
		process_fields:  function() {
			this.temp_data = {};
			return  new Promise((resolve, reject)=>{
				for(const field in this.step_data){
					const val = this.step_data[field];
					if(Array.isArray(val) && val.length>0 && !arrPlainEquals(val, [0, 0])){
						this.temp_data[field] = val;
						console.log( val );
					}
					// if (typeof val === 'string' && val.length>0)
					// 	formData.append(field,this.step_data[field]);
				}
				this.temp_data['tab'] = this.step_data.tab;
				resolve();
			})
		},

		willStepBeSent: function() {

			if ( this.step_data.sexo.length > 0 ||
				!arrPlainEquals(this.step_data.manual_old, [0, 0]) ||
				this.step_data.ranges_old.length > 0 ||
				this.step_data.steps_old.length > 0 ||
				this.step_data.generation_old.length > 0 ||
				this.step_data.escolaridad.length > 0 ||
				this.step_data.estadocivil.length > 0 ||
				this.step_data.numpersonaseneldom.length > 0 ||
				this.step_data.numpersonastrabajan.length > 0 || 
				this.step_data.min_salary.length > 0 || 
				!arrPlainEquals(this.step_data.ing_salary, [0,0]) ||
				this.step_data.level_salary.length > 0 ) {
				
				return true;
			}

			return false;

		}
	}
}
	




</script>
<style lang="scss" scoped></style>