<template lang="pug">
	.section_segmenter
		v-container(fluid)
			v-row(justify="center")
				v-col(sm="10" class="pt-0")
					v-stepper( v-model="currentStep" tile class="my-0")
						v-stepper-header
							template( v-for="step in steps")
								v-stepper-step(
									:key="`${step.number}-step`"
									:complete="currentStep > step.number"
									:step="step.number") {{ step.title }}
								v-divider(
									v-if="step.number !== steps.length"
									:key="step.id")
						v-stepper-items
							v-stepper-content( step="1")
								div(class="text-body-2") * Selecciona el tema que deseas utilizar
								v-tabs(v-model="tab")
									v-tab( v-for="item in items" :key="item"  ) {{ item }}
									v-tab-item
										modelos-component( v-on:nextStep="nextStep()")
									v-tab-item
										sociodemograficos-component( v-on:nextStep="nextStep()")
									v-tab-item
										comportamiento-component( v-on:nextStep="nextStep()")
									v-tab-item
										comportamiento-compra-component( v-on:nextStep="nextStep()")
							//-v-stepper-items
							v-stepper-content( step="2")
								geograficos-component(v-on:nextStep="nextStep()"  v-on:prevStep="prevStep()")
							//-v-stepper-items
							v-stepper-content( step="3")
								datos-component(v-on:nextStep="nextStep()"  v-on:prevStep="prevStep()")
							//-v-stepper-items
							v-stepper-content( step="4")
								formulario-component( v-on:nextStep="nextStep()"
									:show_alert="show_alert"
									v-on:prevStep="prevStep()"
									:btn_save_loading="btn_save_loading")

</template>

<script>
	import { mapGetters } from 'vuex';
	// Modelos dinámicos
	import ModelosComponent from './components/ModelosComponent'
	import SociodemograficosComponent from './components/SociodemograficosComponent'
	import ComportamientoComponent from './components/ComportamientoComponent'
	import ComportamientoCompraComponent from './components/ComportamientoCompraComponent'
	import GeograficosComponent from './components/GeograficosComponent'
	import DatosComponent from './components/DatosComponent'
	import FormularioComponent from './components/FormularioComponent'
	// Service
	import SegmenterService from '../../services/SegmenterService'
	const segmenterService = new SegmenterService();

	export default {
		name: 'Segmentador',

		components: {
			ComportamientoCompraComponent,
			ModelosComponent,
			SociodemograficosComponent,
			ComportamientoComponent,
			GeograficosComponent,
			DatosComponent,
			FormularioComponent
		},
		computed: {
			...mapGetters(['step1','step2','step3','step4', 'stepsState']),
		},
		data: () => ({
			tab: 0,
			currentStep: 1,
			show_alert: false,
			items: [ 'Modelos', 'Sociodemográficos', 'Tipo de cliente', 'Comportamiento de compra'],
			steps: [
				{ title: 'Temáticos', number: 1 },
				{ title: 'Geográficos', number: 2},
				{ title: 'Datos', number: 3 },
				{ title: 'Formulario', number: 4 }
			],
			btn_save_loading: false
		}),
		methods: {
			send_request:function(){

				var formData = new FormData();

				if( this.stepsState.step1 ) {
					formData.append("step1", JSON.stringify(this.step1));
				}

				if( this.stepsState.step2 ) {
					formData.append("step2", JSON.stringify(this.step2));
				}
				
				formData.append("step3", JSON.stringify(this.step3));
				formData.append("step4", JSON.stringify(this.step4));

				this.btn_save_loading = true;
				segmenterService.segmenter(formData)
				.then((response)=>{
					
				 	console.log(response.data.query);
				 	this.show_alert = true;
					this.btn_save_loading = false;
					
					/*
				 	setTimeout(()=>{
				 		this.show_alert = false;
				 	},60000) */

				 })
				.catch((error)=>{
					alert("Error " + error.response.status + ' - ' + error.response.request.responseText);
					//alert("Error, llenar todos los datos del paso 4")
					//console.log(error.response)
					this.btn_save_loading = false;
				})

				// setTimeout(()=>{
				// 	this.loading = false;
				// },4000)
			},
			nextStep () {
				// Check if is last step
				
				if(this.currentStep == this.steps.length ) {
					this.send_request();
					return;
				}
				//if(this.currentStep == 1 )
				//	console.log(this.step1);
				//else if(this.currentStep == 2 )
				//	console.log(this.step2.length);

				this.currentStep+=1;
				window.scrollTo(0, 0)
			},
			prevStep () {
				// Verifica que no sea menor que el primer elemento
				if (this.currentStep - 1 <= 0) 
					return
				//- this.currentStep = this.steps[this.currentStep - 2]
				this.currentStep-=1;
				window.scrollTo(0, 0)
			}
	}
}
</script>
<style>
	.custom__conatiner {
		padding: 2em 1em;
	} 
</style>



