var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outline":"","tile":""}},[_c('v-card-text',[_c('h2',{staticClass:"text-h5 font-weight-bold blue-500--text text-center"},[_vm._v(" Formulario ")]),_c('p',{staticClass:"text-center mb-0 mt-2"},[_vm._v("Tu archivo esta listo")]),_c('p',{staticClass:"text-center mb-3"},[_vm._v("Pero antes, apoyanos completando la siguiente información.")]),_c('ValidationObserver',{ref:"observer"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-subheader',[_vm._v("Tu nombre")])],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"","error-messages":errors,"value":"nombre"},model:{value:(_vm.step_data.name),callback:function ($$v) {_vm.$set(_vm.step_data, "name", $$v)},expression:"step_data.name"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-subheader',[_vm._v("Área a la que perteneces")])],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"Área a la que perteneces","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"","error-messages":errors,"value":"Área a la que perteneces"},model:{value:(_vm.step_data.area),callback:function ($$v) {_vm.$set(_vm.step_data, "area", $$v)},expression:"step_data.area"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-subheader',[_vm._v("¿Para que utilizaras tu segmento o recomendación?")])],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"¿Para que utilizaras tu segmento o recomendación?","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"","error-messages":errors,"value":"¿Para que utilizaras tu segmento o recomendación?"},model:{value:(_vm.step_data.campaign.target),callback:function ($$v) {_vm.$set(_vm.step_data.campaign, "target", $$v)},expression:"step_data.campaign.target"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-subheader',[_vm._v("¿En que canal utilizaras el segmento o recomendación?")])],1),_c('v-col',{staticClass:"pl-10",attrs:{"cols":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"canal","rules":{'required': !_vm.step_data.campaign.other_channel_enabled}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._l((_vm.radioOptions),function(n,i){return _c('v-checkbox',{key:i,staticClass:"mt-0",attrs:{"label":("" + n),"value":n},model:{value:(_vm.step_data.campaign.channels),callback:function ($$v) {_vm.$set(_vm.step_data.campaign, "channels", $$v)},expression:"step_data.campaign.channels"}})}),(errors)?_c('div',{staticClass:"text-left py-0 my-0",attrs:{"cols":"12","md":"12"}},_vm._l((errors),function(error){return _c('span',{staticClass:"red--text"},[_vm._v(_vm._s(error))])}),0):_vm._e()]}}])}),_c('v-checkbox',{staticClass:"shrink mr-2 mt-0 d-inline-block",attrs:{"hide-details":""},model:{value:(_vm.step_data.campaign.other_channel_enabled),callback:function ($$v) {_vm.$set(_vm.step_data.campaign, "other_channel_enabled", $$v)},expression:"step_data.campaign.other_channel_enabled"}}),_c('ValidationProvider',{attrs:{"name":"Otro","rules":{'required': _vm.step_data.campaign.other_channel_enabled}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"d-inline-block",attrs:{"disabled":!_vm.step_data.campaign.other_channel_enabled,"error-messages":errors,"label":"Otro"},model:{value:(_vm.step_data.campaign.other_channel),callback:function ($$v) {_vm.$set(_vm.step_data.campaign, "other_channel", $$v)},expression:"step_data.campaign.other_channel"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-subheader',[_vm._v("¿Podrias compartirnos el nombre de tu campaña o proyecto?")])],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"nombre de tu campaña o proyecto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"","error-messages":errors,"value":"nombre de tu campaña o proyecto"},model:{value:(_vm.step_data.campaign.name),callback:function ($$v) {_vm.$set(_vm.step_data.campaign, "name", $$v)},expression:"step_data.campaign.name"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-left",attrs:{"cols":"12","md":"4"}},[_c('v-subheader',[_vm._v("¿Cual es la fecha de inicio de tu campaña o proyecto?")]),_c('v-subheader',{staticClass:"mt-5"},[_vm._v(" Si aún no la conoces puedes poner una fecha tentativa")])],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.step_data.campaign.start,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.step_data.campaign, "start", $event)},"update:return-value":function($event){return _vm.$set(_vm.step_data.campaign, "start", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"name":"Fecha de inicio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha de inicio","error-messages":errors,"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.step_data.campaign.start),callback:function ($$v) {_vm.$set(_vm.step_data.campaign, "start", $$v)},expression:"step_data.campaign.start"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"range":"","min":_vm.min_date,"scrollable":""},on:{"change":_vm.inicioDatePicker},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary primary--text"},on:{"click":function($event){_vm.modal = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.date)}}},[_vm._v("OK")])],1)],1)],1)],1),(_vm.$checkPermission(['send_customer_match_to_google_ads', 'send_customer_match_to_facebook']))?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-subheader',[_vm._v("Seleccione el destino de la segmentación")])],1),_c('v-col',{staticClass:"pl-10",attrs:{"cols":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"destino de la segmentación","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"mandatory":""},model:{value:(_vm.step_data.customerMatch.destination),callback:function ($$v) {_vm.$set(_vm.step_data.customerMatch, "destination", $$v)},expression:"step_data.customerMatch.destination"}},[_c('v-radio',{attrs:{"label":"CSV","value":"CSV"}}),(_vm.$checkPermission('send_customer_match_to_google_ads'))?_c('v-radio',{staticClass:"mt-2",attrs:{"label":"Google Ads","value":"GOOGLE_ADS"}}):_vm._e(),(_vm.$checkPermission('send_customer_match_to_facebook'))?_c('v-radio',{staticClass:"mt-2",attrs:{"label":"Facebook","value":"FACEBOOK"}}):_vm._e(),(errors)?_c('div',{staticClass:"text-left py-0 my-0",attrs:{"cols":"12","md":"12"}},_vm._l((errors),function(error){return _c('span',{staticClass:"red--text"},[_vm._v(_vm._s(error))])}),0):_vm._e()],1)]}}],null,false,3289322618)})],1)],1):_vm._e(),(_vm.is_GOOGLE_ADS_or_FACEBOOK_choosen)?_c('v-row',[_c('v-col',{staticClass:"text-left",attrs:{"cols":"12","md":"4"}},[_c('v-subheader',[_vm._v("¿Desea automatizar esta audiencia?")])],1),_c('v-col',{staticClass:"pl-10",attrs:{"cols":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"automatizar audiencia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"mandatory":"","row":""},model:{value:(_vm.step_data.customerMatch.enableAutomation),callback:function ($$v) {_vm.$set(_vm.step_data.customerMatch, "enableAutomation", $$v)},expression:"step_data.customerMatch.enableAutomation"}},[_c('v-radio',{attrs:{"label":"No","value":false}}),_c('v-radio',{attrs:{"label":"Si","value":true}})],1),(errors)?_c('div',{staticClass:"text-left py-0 my-0",attrs:{"cols":"12","md":"12"}},_vm._l((errors),function(error){return _c('span',{staticClass:"red--text"},[_vm._v(_vm._s(error))])}),0):_vm._e()]}}],null,false,2520816623)})],1)],1):_vm._e(),(_vm.step_data.customerMatch.enableAutomation)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-subheader',[_vm._v("Frecuencia de la automatización")])],1),_c('v-col',{staticClass:"pl-10",attrs:{"cols":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"frecuencia de la automatización","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.frequencyValues,"error-messages":errors,"label":"Seleciona una opción","chips":""},model:{value:(_vm.step_data.customerMatch.frequency),callback:function ($$v) {_vm.$set(_vm.step_data.customerMatch, "frequency", $$v)},expression:"step_data.customerMatch.frequency"}})]}}],null,false,2219910881)})],1)],1):_vm._e(),(_vm.step_data.customerMatch.enableAutomation)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-subheader',[_vm._v("Fechas para automatización")])],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-dialog',{ref:"dialoStartDateAutSegmenter",attrs:{"return-value":_vm.step_data.customerMatch.startDate,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.step_data.customerMatch, "startDate", $event)},"update:return-value":function($event){return _vm.$set(_vm.step_data.customerMatch, "startDate", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha inicio","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.step_data.customerMatch.startDate),callback:function ($$v) {_vm.$set(_vm.step_data.customerMatch, "startDate", $$v)},expression:"step_data.customerMatch.startDate"}},'v-text-field',attrs,false),on))]}}],null,false,679541854),model:{value:(_vm.modalStartDateAutSegmenter),callback:function ($$v) {_vm.modalStartDateAutSegmenter=$$v},expression:"modalStartDateAutSegmenter"}},[_c('v-date-picker',{attrs:{"scrollable":"","min":_vm.min_date},model:{value:(_vm.step_data.customerMatch.startDate),callback:function ($$v) {_vm.$set(_vm.step_data.customerMatch, "startDate", $$v)},expression:"step_data.customerMatch.startDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalStartDateAutSegmenter = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialoStartDateAutSegmenter.save(_vm.step_data.customerMatch.startDate)}}},[_vm._v("OK")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-dialog',{ref:"dialoEndDateAutSegmenter",attrs:{"return-value":_vm.step_data.customerMatch.endDate,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.step_data.customerMatch, "endDate", $event)},"update:return-value":function($event){return _vm.$set(_vm.step_data.customerMatch, "endDate", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha final","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.step_data.customerMatch.endDate),callback:function ($$v) {_vm.$set(_vm.step_data.customerMatch, "endDate", $$v)},expression:"step_data.customerMatch.endDate"}},'v-text-field',attrs,false),on))]}}],null,false,1983816214),model:{value:(_vm.modalEndDateAutSegmenter),callback:function ($$v) {_vm.modalEndDateAutSegmenter=$$v},expression:"modalEndDateAutSegmenter"}},[_c('v-date-picker',{attrs:{"scrollable":"","min":_vm.minEndDateAutCustomerMatch,"max":_vm.maxEndDateAutCustomerMatch},model:{value:(_vm.step_data.customerMatch.endDate),callback:function ($$v) {_vm.$set(_vm.step_data.customerMatch, "endDate", $$v)},expression:"step_data.customerMatch.endDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalEndDateAutSegmenter = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialoEndDateAutSegmenter.save(_vm.step_data.customerMatch.endDate)}}},[_vm._v("OK")])],1)],1)],1)],1):_vm._e(),(_vm.is_GOOGLE_ADS_choosen)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-subheader',[_vm._v("Selecciona una cuenta de Google Ads")])],1),_c('v-col',{staticClass:"pl-10",attrs:{"cols":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"MCC","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.MCC,"error-messages":errors,"label":"Seleciona una opción","chips":""},model:{value:(_vm.step_data.customerMatch.mcc_account),callback:function ($$v) {_vm.$set(_vm.step_data.customerMatch, "mcc_account", $$v)},expression:"step_data.customerMatch.mcc_account"}})]}}],null,false,1207560999)})],1)],1):_vm._e(),(_vm.is_CSV_choosen)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-subheader',[_vm._v("¿Cómo quieres nombrar tu archivo?")])],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"archivo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"","error-messages":errors,"value":"archivo"},model:{value:(_vm.step_data.filename),callback:function ($$v) {_vm.$set(_vm.step_data, "filename", $$v)},expression:"step_data.filename"}})]}}],null,false,738753102)})],1)],1):_vm._e(),(_vm.is_GOOGLE_ADS_or_FACEBOOK_choosen)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-subheader',[_vm._v("¿Cómo quieres nombrar a tu audiencia? ")])],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"archivo","rules":"required|alphabetNumersUnderScore"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"","error-messages":errors,"value":"archivo"},model:{value:(_vm.step_data.customerMatch.audienceName),callback:function ($$v) {_vm.$set(_vm.step_data.customerMatch, "audienceName", $$v)},expression:"step_data.customerMatch.audienceName"}})]}}],null,false,3765226085)})],1)],1):_vm._e(),_c('v-row',[(_vm.show_alert)?_c('v-col',[_c('v-expand-transition',[_c('v-alert',{attrs:{"type":"success"}},[_vm._v(" Procesando... recibirás un correo electrónico con la segmentación. "),_c('v-btn',{attrs:{"color":"blue-700","small":""},on:{"click":_vm.reloadPage}},[_vm._v("Crear otra segmentación")])],1)],1)],1):_vm._e()],1)],1)],1),_c('v-card-actions',{staticClass:"px-0 text-end",attrs:{"justify":"end"}},[_c('v-btn',{attrs:{"color":"grey","dark":"","tile":"","elevation":""},on:{"click":_vm.prevStep}},[_vm._v(" Anterior ")]),_c('v-spacer'),_c('v-btn',{staticClass:"text-none",attrs:{"color":"primary","tile":"","elevation":"","loading":_vm.btn_save_loading,"disabled":_vm.btn_save_loading || _vm.show_alert},on:{"click":_vm.save}},[_vm._v(" ¡Listo! Enviar datos")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }