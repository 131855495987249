<template lang="pug">
	v-card( outline tile)
		//- v-card-title( class="justify-center") 
		v-row(justify="center")
			v-col( cols="12" sm="8")
				h6(class="text-body-2  text-center mt-5") Aquí puedes realizar segmentaciones o recomendar productos a clientes Coppel con el uso de Modelos de Machine Learning creados y diseñados por Analitica Digital.
			
			//-v-col( cols="12")
				h2( class="text-h5 text-center font-weight-bold blue-500--text mt-5") ¿Que tipo de modelo te interesa utilizar?
			//-v-col( cols="12" class="d-flex justify-center")
				v-checkbox(
					class="mr-2"
					v-for="n in opciones"
					v-model="step_data.type_model"
					:key="n"
					:label="`${n}`"
					:value="n")
				

		
		ValidationObserver( ref="observer")
			v-card-text( class="px-3" v-if="step_data.type_model === 'Segmentación de clientes' ")
				v-row( justify="center" align="center")
					v-col( sm="12")
						h1( class="text-center text-h6 blue-500--text") Modelos de segmentación
						p( class="text-center my-3") Elige el modelo de segmentación de clientes que mas se ajuste a tus necesidades.
				
				v-row( justify="center" align="center")
					v-col( sm="1" justify="center" class="text-center")
						v-checkbox(
							v-model="step_data.segment"
							value="rfm"
							hide-details
							class="my-0 mx-auto text-center")
					v-col(sm="11")
						h2(class="text-subtitle-1 blue-600--text font-weight-bold") RFM por categorías
						p(class="blue-600--text mt-2") ¿Qué hace?
						p(class="mb-0 ") Consiste en clasificar a los  clientes Coppel digitales mediante tres variables indicadoras de los últimos 2 años.
						p(class="mb-0 ") <span class="font-weight-bold"> Recencia: </span> Tiempo transcurrido desde su última compra.
						p(class="mb-0 ") <span class="font-weight-bold"> Frecuencia: </span> Número  de compras.
						p(class="mb-0 ") <span class="font-weight-bold"> Monetario: </span> Valor de las compras totales del cliente.
						p(class="mb-0 mt-2") Además puedes combinarlas por áreas, categorías de productos y tipo de compra. Este modelo califica cada variable del<span class="font-weight-bold blue-500--text"> 1 al 4</span>, donde <span class="font-weight-bold blue-500--text"> 4 </span> es el valor más alto. 


				// fields RFM 
				v-row( justify="end" align="center")
					v-col(v-if="step_data.segment=='rfm'" sm="11")
						v-expand-transition
							v-row
								v-col(sm="4")
									p(class="text-subtitle-1 blue-600--text font-weight-bold mb-0") Área de productos
									label(class="mt-2 text-caption") Elige una o varias opciones.
									v-checkbox(
											label="Muebles"
											v-model="step_data.des_area"
											outlined
											value="Muebles")
									v-checkbox(
											label="Ropa"
											v-model="step_data.des_area"
											outlined
											value="Ropa")
								v-col(sm="4")
									p(class="text-subtitle-1 blue-600--text font-weight-bold mb-0") Categoría de productos
									v-select(
										label="Selecciona una o varias categorías."
										v-model="step_data.des_categoria"
										multiple
										small-chips
										:items="des_categoria")
								v-col(sm="4")
									p(class="text-subtitle-1 blue-600--text font-weight-bold mb-0") Tipo de compra
									label(class="mt-2 text-caption")  Selecciona uno o varios tipos de compra.
									v-checkbox(
											label="Contado"
											v-model="step_data.des_tipocompra"
											outlined
											value="Contado")
									v-checkbox(
											label="Crédito"
											v-model="step_data.des_tipocompra"
											outlined
											value="Crédito coppel")
						v-expand-transition
							v-row
								v-col(sm="12")
									p(class="mb-0") De acuerdo a las categorías que seleccionaste. Elige las variables RFM más adecuadas a tu campaña.  Puedes combinar todas o solo la más relevante para tí. Recuerda que el valor 4 es el más alto.
									p(class="mb-0") <b> Ejemplo: </b> Recencia 4 indica que un cliente compró recientemente ese tipo de categoría.  Frecuencia 4: El mayor número de veces que un cliente compra esa categoría. Monetario 4: El cliente gastó más en sus compras.        

								v-col(sm="4")
									p(class="text-subtitle-1 blue-600--text mb-0") R: Recencia
									label(class="text-caption") Tiempo transcurrido desde su última compra

									
									v-checkbox(
											v-for="(item,index) of segments"
											:key="`recency-${index}`"
											:label="''+item"
											v-model="step_data.RecencyVAL"
											outlined
											:value="item")
								v-col(sm="4")
									p(class="text-subtitle-1 blue-600--text mb-0") F: Frecuencia
									label(class="text-caption") Número  de compras.
									v-checkbox(
										v-for="(item,index) of segments"
										:key="`frecuency-${index}`"
										:label="''+item"
										v-model="step_data.FrecuencyVAL"
										outlined
										:value="item")
								v-col(sm="4")
									p(class="text-subtitle-1 blue-600--text mb-0") M: Monetario
									label(class="text-caption") Valor de las compras totales del cliente.
									v-checkbox(
										v-for="(item,index) of segments"
										:key="`monetary-${index}`"
										:label="''+item"
										v-model="step_data.MonetaryVAL"
										outlined
										:error-messages="errors"
										:value="item")
								
				v-row( justify="end" align="center")
					v-col(sm="11")
						v-divider
				v-row( justify="center" align="center")
					v-col( sm="1")
						v-checkbox(
							v-model="step_data.segment"
							value="spc"
							hide-details
							class="")
					v-col(sm="11")
						h2(class="text-subtitle-1 blue-600--text font-weight-bold") SPC
						p(class="blue-600--text mt-2") ¿Qué hace?
						p(class="mb-0 ") Este modelo clasifica a los clientes de acuerdo al número de compras y tiempo transcurrido entre compras por medios digitales y los divide por estrategia de comunicación y probabilidad de compra.
				// fields spc v-expand-transition
				v-row( justify="end" align="center")
					v-col(v-if="step_data.segment=='spc'"  sm="11")
						v-expand-transition
							v-row
								v-col(sm="12")
									p(class="mb-0 text-center") Elige las variables del modelo SPC "Probabilidad de compra" mas adecuadas a tu campaña.
								v-col(sm="6")
									p(class="text-subtitle-1 blue-600--text") Retencion del cliente
									
									v-checkbox(
											v-for="(item,index) of spc_retention"
											:key="`retention-${index}`"
											:label="item.text"
											v-model="step_data.spc"
											outlined
											:value="item.value")
								v-col(sm="6")
									p(class="text-subtitle-1 blue-600--text") Recuperacion del cliente
									v-checkbox(
											v-for="(item,index) of spc_recovery"
											:key="`recovery-${index}`"
											:label="item.text"
											v-model="step_data.spc"
											outlined
											:error-messages="errors"
											:value="item.value")
				
				v-row( justify="end" align="center")
					v-col(sm="11")
						v-divider
						
				v-row( justify="end" align="center")
					v-col( sm="1" justify="center" class="text-center")
						v-checkbox(
							v-model="step_data.segment"
							value="puntuality"
							hide-details
							class="my-0 mx-auto text-center")
					v-col(sm="11")
						h2(class="text-subtitle-1 blue-600--text font-weight-bold") Puntualidad "N"
						p(class="blue-600--text mt-2") ¿Qué hace?
						p(class="mb-0 ") Utiliza diversas variables económicas, demográficas y de perfil del cliente que permite predecir la puntualidad de abono para Clientes clasificación "N", mejor conocidos como clientes Nuevos.
						p(class="mb-0 ") Elige la puntualidad predictiva calculada por el modelo de Puntualidad "N" más adecuadas a tu campaña.

				
				v-row( justify="center" align="center" v-if="step_data.segment=='puntuality'")
					v-col(sm="2" class="" v-for="(item, index) of puntuality" :key="`puntuality-${index}`")
						ValidationProvider(
							v-slot="{ errors }"
							:name="`Cliente `"
							rules="required")
							v-checkbox(
								:label="`Cliente ${item}`"
								v-model="step_data.puntuality"
								outlined
								:error-messages="errors"
								:value="item")
				v-divider(class="mt-10")
		v-card-actions
			v-spacer
			v-btn( color="primary" @click="onClickSaveBtn"  tile elevation="0") Siguiente
</template>
<script>
import { is } from 'vee-validate/dist/rules'
//import { required, digits, email, max, regex } from 'vee-validate/dist/rules'
//import { mapGetters } from 'vuex';
export default {
	name: 'ModelosComponent',
	props: {	},
	computed: {
		//...mapGetters(['step1']),
	},
	data() {
		return {
			errors: '',
			opciones: ['Segmentación de clientes', 'Recomendación de productos'],
			segments: [4,3,2,1], // R F M
			spc_retention : [{text:'Alta probabilidad de compra',value:'C1'},{text:'Baja probabilidad de compra',value:'C2'}],
			spc_recovery  : [{text:'Alta probabilidad de compra',value:'C3'},{text:'Baja probabilidad de compra',value:'C4'}],
			puntuality	  : ['A','B', 'C','D','Z'],
			des_categoria : ['Bebes','Blancos','Caballero exterior','Caballeros accesorios','Celulares','Cuidado personal','Dama joven','Dama señora','Deportivo','Enseres domésticos','Escolar','Hogar','Impulso','Interior y calceteria','Joyería y relojería','Juguetes','Juguetes, bebés y aparatos de ejercicio','Lencería','Línea blanca','Mascotas','Niñas','Niños','Recamara','Tecnologia y entretenimiento','Transporte y movilidad','Zapato caballero','Zapato dama','Zapato infantil','Óptica'],

			
			fields_rfm :["des_area","des_categoria","des_tipocompra","RecencyVAL","FrecuencyVAL","MonetaryVAL"],

			step_data :{
				segment: '',
				type_model: 'Segmentación de clientes', // 'Segmentación de clientes' | 'Recomendación de productos'

				des_area: [],
				des_categoria:[],
				des_tipocompra: [],
				RecencyVAL: [],
				FrecuencyVAL: [],
				MonetaryVAL: [],

				spc: [],

				puntuality: []
			},
			temp_data : {},
			
		}
	},
	methods: {
		onClickSaveBtn:  function() {
			this.$refs.observer.validate().then( async res=> {
				//- if the form is valid
				if(res){
					this.$store.dispatch('update_step_state', { step1: this.willStepBeSent() })
					await this.process_fields()
					.then(resolve=>{
						this.$store.dispatch('step1_data',this.temp_data);
						this.$emit('nextStep');
					})
					.catch(error=>{
						console.log(error);
					});
				}
			})
		},
		process_fields:  function(){
			this.temp_data = {};
			return  new Promise((resolve, reject)=>{
				
				
				if( this.step_data.segment!="")
					this.temp_data.segment = this.step_data.segment;
				if(this.step_data.type_model!="")
					this.temp_data.type_model = this.step_data.type_model;
				//- Loop only fields of RFM
				if(this.step_data["segment"]=="rfm"){
					for(const field of this.fields_rfm){
						const val = this.step_data[field];
						if(Array.isArray(val) && val.length>0){
							this.temp_data[field] = val;
							console.log( val );
						}
					}
				}
				else if(this.step_data["segment"]=="spc"){
					const val = this.step_data.spc;
					if(Array.isArray(val) && val.length>0)
						this.temp_data["spc"] = val;
				}
				else if(this.step_data["segment"]=="puntuality"){
					const val = this.step_data.puntuality;
					if(Array.isArray(val) && val.length>0)
						this.temp_data["puntuality"] = val;
				}

				resolve();
			})
		},

		// 
		willStepBeSent() {

			if( this.step_data.segment == 'rfm' && ( this.step_data.des_area.length > 0 || this.step_data.des_categoria.length > 0 || this.step_data.des_tipocompra.length > 0 ) ) {
				return true;
			}

			if( this.step_data.segment == 'spc' && this.step_data.spc.length > 0  ) {
				return true;
			}

			if( this.step_data.segment == 'puntuality' && this.step_data.puntuality.length > 0 ) {
				return true;
			}

			return false;

		}
	}
}
</script>