<template lang="pug">
	v-card(outline tile)
		v-row(justify="center")
			v-col( cols="12" sm="12" class="ml-5 text-center" )
				p(class="text-body-2 text-center mt-5 mb-0")  En esta sección puedes filtrar las características del cliente Coppel.
					ValidationObserver(ref='observer')
		ValidationObserver(ref='observer')
			v-card-text(v-if="isRequiredFilter")
				v-row(justify="center" color="primary")
					v-col(cols='10' class="my-5" )
						v-divider
				v-row
					v-col(cols='12' md='4')
						//- ValidationProvider(v-slot='{ errors }' name='puntualidad' rules='required')
						p( class="text-title font-weight-bold blue-500--text mt-5") Puntualidad del cliente
						v-select( 
							:items="puntuality" 
							label="Selecciona una o varias opciones"
							v-model='step_data.puntualidadcliente'
							multiple chips)
					v-col(cols='12' md='4')
						//- p(class="blue-500--text") Tipo de cliente
							ValidationProvider(v-slot='{ errors }' name='tipo de cliente' rules='required')
						p( class="text-title font-weight-bold blue-500--text mt-5") Tipo de Cliente
						v-select( 
							:items="client" 
							label="Selecciona una o varias opciones"
							v-model='step_data.des_TipoCliente'
							multiple chips)
					v-col(cols='12' md='4')
						//- ValidationProvider(v-slot='{ errors }' name='es cliente' rules='required')
						p( class="text-title font-weight-bold blue-500--text mt-5") Cliente Digital
						v-select( 
							:items="isClient" 
							label="Selecciona una o varias opciones"
							v-model='step_data.flag_ClienteDigital')
					v-col(cols='12' md='4')
						//- ValidationProvider(v-slot='{ errors }' name='Antiguedad del cliente' rules='required')
						p( class="text-title font-weight-bold blue-500--text mt-5") Antigüedad del cliente en Coppel
						label(class="text-caption") Elige el periodo de antigüedad como Cliente Coppel. (Calculado en años)
						p(class="text-caption text-end") Rango: {{step_data.old[0]}} - {{step_data.old[1]}}
						v-range-slider(
							step='1'
							min='0' max='15'
							v-model="step_data.old"
							class="mt-2")
					v-col(cols='12' md='4')
						p( class="text-title font-weight-bold blue-500--text mt-5") Última interacción del cliente en Coppel
						label(class="text-caption") Elige el periodo de la última actividad del cliente en Coppel. (Calculado en meses)
						p(class="text-caption text-end") Rango: {{step_data.activity[0]}} - {{step_data.activity[1]}}
						v-range-slider(
								step='1'
								min='0' max='24'
								v-model="step_data.activity"
								class="mt-2")
				//- Dates
				v-row
					v-col(cols='12' sm='6' md='4')
						v-menu(
							:close-on-content-click="false"
							:nudge-right="40"
							transition="scale-transition"
							offset-y
							min-width="auto")
							template(v-slot:activator='{ on, attrs }')
								//- ValidationProvider(v-slot='{ errors }' name='Fecha de ultima actividad en ropa' rules='required')
								p( class="text-title font-weight-bold blue-500--text mt-5") Última actividad en área ropa
								v-text-field(
									v-model="step_data.date_ropa"
									label="Selecciona una fecha o intervalo de fechas"
									prepend-icon="mdi-calendar"
									readonly
									v-bind="attrs"
									v-on="on")
							v-date-picker(
								v-model="step_data.date_ropa"
								@input="menu = false"
								@change="areaRopaDatePicker"
								range
								:max="max_date")
					v-col(cols='12' sm='6' md='4')
						v-menu(
							:close-on-content-click="false"
							:nudge-right="40"
							transition="scale-transition"
							offset-y
							min-width="auto")
							template(v-slot:activator='{ on, attrs }')
								//- ValidationProvider(v-slot='{ errors }' name='Fecha de ultima actividad en Muebles' rules='required')
								p( class="text-title font-weight-bold blue-500--text mt-5") Última actividad en área muebles
								v-text-field(
									v-model="step_data.date_muebles"
									label="Selecciona una fecha o intervalo de fechas"
									prepend-icon="mdi-calendar"
									readonly
									v-bind="attrs"
									v-on="on")
							v-date-picker(
								v-model="step_data.date_muebles"
								@input="menu = false"
								range
								@change="areaMueblesDatePicker"
								:max="max_date")
					v-col(cols='12' sm='6' md='4')
						v-menu(
							:close-on-content-click="false"
							:nudge-right="40"
							transition="scale-transition"
							offset-y
							min-width="auto")
							template(v-slot:activator='{ on, attrs }')
								//- ValidationProvider(v-slot='{ errors }' name='Fecha de ultima actividad en tiempo Aire' rules='required')
								p( class="text-title font-weight-bold blue-500--text mt-5") Última actividad en Tiempo Aire
								v-text-field(
									v-model="step_data.date_tiempoaire"
									label="Selecciona una fecha o intervalo de fechas "
									prepend-icon="mdi-calendar"
									readonly
									v-bind="attrs"
									v-on="on")
							v-date-picker(
								v-model="step_data.date_tiempoaire"
								@change="tiempoAireDatePicker"
								@input="menu = false"
								range
								:max="max_date")
				//-  Credito y abonos del cliente
				v-row(justify="center")
					v-col(cols='10' )
						v-divider(class="my-5")
						p( class="text-h6 font-weight-bold blue-500--text mt-5 text-center") Crédito y abonos del cliente
						p(class="text-body-2 text-center mt-2 mb-0") En esta sección podras seleccionar algunas de las carateristicas del crédito y abonos digitales del cliente
				v-row
					v-col(cols='12' sm='6' md='4')
						p( class="text-title font-weight-bold blue-500--text mt-5") Último movimiento de Abono
						v-menu(
							:close-on-content-click="false"
							:nudge-right="40"
							transition="scale-transition"
							offset-y
							min-width="auto")
							template(v-slot:activator='{ on, attrs }')
								//- ValidationProvider(v-slot='{ errors }' name='Fecha del ultimo movimiento de Abono' rules='required')
								v-text-field(
									v-model="step_data.date_abono"
									label="Selecciona una fecha o intervalo de fechas "
									prepend-icon="mdi-calendar"
									readonly
									v-bind="attrs"
									v-on="on")
							v-date-picker(
								v-model="step_data.date_abono"
								range
								@change="ultimoAbonoDatePicker"
								@input="menu = false" 
								:max="max_date")
					v-col(cols='12' md='4')
						//- ValidationProvider(v-slot='{ errors }' name='canal abono' rules='required')
						p( class="text-title font-weight-bold blue-500--text mt-5") Canal de abono
						v-select( 
								:items="chanels" 
								label="Selecciona una o varias opciones"
								v-model='step_data.des_canal'
								multiple chips)

					v-col(cols='12' md='4')
						//- ValidationProvider(v-slot='{ errors }' name='Linea de Crédito' rules='required')
						p( class="text-title font-weight-bold blue-500--text mt-5") Linea de crédito real (MXN) <span class="ml-1 text-caption text-end black--text"> Rango: {{step_data.credit[0]}} - {{step_data.credit[1]}}</span>
						p(class="text-caption") Selecciona el intervalo de la línea de crédito otorgada al cliente 
						v-range-slider(
							step='10000'
							min='0' max='2172413'
							v-model="step_data.credit"
							class="mt-4")
					
					v-col(cols='12' md='4')
						//- ValidationProvider(v-slot='{ errors }' name='Importe Abonado' rules='required')
						p( class="text-title font-weight-bold blue-500--text mt-5") Importe abonado (MXN)
						label(class="text-caption") Selecciona el intervalo del importe que abonó el cliente.
						p(class="text-caption text-end mt-1") Rango: {{step_data.importe[0]}} - {{step_data.importe[1]}}
						v-range-slider(
							step='1'
							min='0' max='108820'
							v-model="step_data.importe"
							class="mt-10")
					
			v-card-actions.px-4.text-end(justify='end')
				v-btn.ml-auto(color='primary' @click='onClickSaveBtn')  Siguiente
</template>

<script>
import { required, digits, email, max, regex } from 'vee-validate/dist/rules'

import { arrPlainEquals } from '../../../utils/helpers'

export default {
	name: 'ComportamientoComponent',
	
	 data: () => ({
		isRequiredFilter: true,

		puntuality:[
			{text:"Cliente A",value:"A"},
			{text:"Cliente B",value:"B"},
			{text:"Cliente C",value:"C"},
			{text:"Cliente D",value:"D"},
			{text:"Cliente nuevo",value:"N"},
			{text:"Cliente moroso",value:"Z"},
			{text:"Sin Dato/No incluir",value:"unidentified"},
		],
		client:[
			{text:"Contado",value:"CLIENTE CONTADO"},
			{text:"Credito Titular",value:"CLIENTE CREDITO TITULAR"},
			{text:"Credito Adicional",value:"CLIENTE CREDITO ADICIONAL"},
			{text:"Credito Especial",value:"CLIENTE CREDITO ESPECIAL"},
			{text:"Empleado Titular",value:"EMPLEADO TITULAR"},
			{text:"Empleado Adicional",value:"EMPLEADO ADICIONAL"},
			//{text:"Cliente moroso",value:"moroso"},
			//{text:"Sin Dato/No incluir",value:"Sin Dato/No incluir"},
		],
		isClient:[
			{text:"Sí", value:1},
			{text:"No", value:0},
		],
		chanels:[
			{text:"Coppel.com", value:"APP COPPEL"},
			{text:"App coppel", value:"COPPEL.COM"},
			{text:"Whatsapp", value:"WHATSAPP"},
			{text:"Sin identificar", value:"unidentified"},
		],
		max_date: new Date().toISOString().substr(0, 10),
		menu: false,

		step_data :{
			tab: 'tipocliente',
			puntualidadcliente:[],
			des_TipoCliente:[],
			flag_ClienteDigital: -1,
			old:[0,0],
			activity:[0,0], //*
			date_ropa: [],
			date_muebles: [],
			date_tiempoaire: [],
			importe:[0,0], //*
			des_canal:[], //*
			credit:[0,0], //*
			date_abono: [],
		},
		temp_data : {}
	
	}),

	mounted () {
	},
	methods:{
		onClickSaveBtn:  function() {
			this.$refs.observer.validate().then( async res=> {
				//- if the form is valid
				if(res){
					this.$store.dispatch('update_step_state', { step1: this.willStepBeSent() })
					await this.process_fields()
					.then(resolve=>{
						this.$store.dispatch('step1_data',this.temp_data);
						this.$emit('nextStep');
					})
					.catch(error=>{
						console.log(error);
					});
				}
			})
		},
		// si la fecha 1 es mayor a la fecha 2 en el rango se invierten los valores
		evalRangeDatePicker(range) {
			var _range = Object.assign([], range)
			if ( new Date(_range[0]).getTime() > new Date(_range[1]).getTime() ) {
				const [from, to] = _range;
				_range = [to, from];
			}
			return _range;
		},
		areaRopaDatePicker: function(range) {
			this.step_data.date_ropa = this.evalRangeDatePicker(range);
		},
		areaMueblesDatePicker: function(range) {
			this.step_data.date_muebles = this.evalRangeDatePicker(range);
		},
		tiempoAireDatePicker: function(range) {
			this.step_data.date_tiempoaire = this.evalRangeDatePicker(range);
		},
		ultimoAbonoDatePicker: function(range) {
			this.step_data.date_abono = this.evalRangeDatePicker(range);
		},
		process_fields:  function(){
			this.temp_data = {}
			return  new Promise((resolve, reject)=>{
				
				for(const field in this.step_data){
					const val = this.step_data[field];

					if(Array.isArray(val) && val.length>0 && !arrPlainEquals( val, [0,0] ) ){
						this.temp_data[field] = val;
					}
					if ( this.step_data['flag_ClienteDigital'] != -1 ) { 
						this.temp_data['flag_ClienteDigital'] = this.step_data['flag_ClienteDigital'];
					}
					//- if (typeof val === 'string' && val.length>0)
					//- 	formData.append(field,this.step_data[field]);
				}
				
				this.temp_data['tab'] = this.step_data.tab;

				resolve();
			})
		},
		willStepBeSent: function() {

			if ( this.step_data.puntualidadcliente.length > 0 ||
				this.step_data.des_TipoCliente.length > 0 ||
				this.step_data.flag_ClienteDigital !== -1 ||
				!arrPlainEquals( this.step_data.old, [0,0] ) ||
				!arrPlainEquals( this.step_data.activity, [0,0] ) ||
				this.step_data.date_ropa.length > 0 ||
				this.step_data.date_muebles.length > 0 ||
				this.step_data.date_tiempoaire.length > 0 ||
				this.step_data.date_abono.length > 0 ||
				this.step_data.des_canal.length > 0 ||
				!arrPlainEquals( this.step_data.credit, [0,0]) ||
				!arrPlainEquals( this.step_data.importe, [0,0] ) ) {
				
				return true;
			}

			return false;
		}
	}
	
}
</script>